import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface Palette {
		border: Palette["primary"];
	}
	interface PaletteOptions {
		border: PaletteOptions["primary"];
	}
}

// Create a theme instance.
const theme = createTheme({
	palette: {
		primary: {
			main: "#172c55"
		},
		secondary: {
			main: "#FF7D32"
		},
		success: {
			main: "#2dd36f",
			contrastText: "#fff"
		},
		info: {
			main: "#3880ff"
		},
		warning: {
			main: "#ffc409"
		},
		error: {
			main: "#eb445a"
		},
		border: {
			main: "#0000001f"
		},
		background: {
			default: "#fafaff",
			paper: "#ffffff"
		}
	},
	shape: {
		borderRadius: 8
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				colorInherit: {
					backgroundColor: "#ff910f",
					color: "#fff"
				}
			},
			defaultProps: {
				color: "inherit"
			}
		},
		MuiButton: {
			defaultProps: {
				size: "medium"
			}
		},
		MuiButtonGroup: {
			defaultProps: {
				size: "medium"
			}
		},
		MuiCheckbox: {
			defaultProps: {
				size: "small"
			}
		},
		MuiFab: {
			defaultProps: {
				size: "medium"
			}
		},
		MuiFormControl: {
			defaultProps: {
				margin: "dense",
				size: "small"
			}
		},
		MuiFormHelperText: {
			defaultProps: {
				margin: "dense"
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: "medium"
			}
		},
		MuiInputBase: {
			defaultProps: {
				margin: "dense"
			}
		},
		MuiInputLabel: {
			defaultProps: {
				margin: "dense"
			}
		},
		MuiRadio: {
			defaultProps: {
				size: "small"
			}
		},
		MuiSwitch: {
			defaultProps: {
				size: "small"
			}
		},
		MuiTextField: {
			defaultProps: {
				margin: "dense",
				size: "small"
			}
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true
			}
		},
		MuiGrid: {
			defaultProps: {
				xs: 12
			}
		}
	},
	typography: {
		fontSize: 14,
		htmlFontSize: 18
	}
});

export default theme;
