import * as actions from "store/actions/lead";
import ILead from "Types/Lead";

export interface LeadState {
	loading: any;
	items: ILead[];
	errors: any;
}

const initialState: LeadState = {
	loading: {},
	items: [],
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_ALL_LEAD: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ALL_LEAD_SUCCESS: {
			return {
				...initialState,
				items: payload || []
			};
		}
		case actions.GET_ALL_LEAD_FAILED: {
			return {
				...state,
				loading: { etAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
