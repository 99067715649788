import * as api from "services/api";
import { getErrors } from "helpers/error";
import IVideo from "Types/Video";
import { Callback } from "Types/Callback";
import { shuffle } from "helpers/array";

export const CREATE_VIDEO = "[VIDEO CREATE] CREATE";
export const CREATE_VIDEO_SUCCESS = "[VIDEO CREATE] CREATE_SUCCESS";
export const CREATE_VIDEO_FAILED = "[VIDEO CREATE] CREATE_FAILED";

export const GET_ALL_VIDEO = "[VIDEO] GET_ALL";
export const GET_ALL_VIDEO_SUCCESS = "[VIDEO] GET_ALL_SUCCESS";
export const GET_ALL_VIDEO_FAILED = "[VIDEO] GET_ALL_FAILED";

export const SET_VALUE = "[VIDEO] SET_VALUE";
export const CLEAR_VALUES = "[VIDEO] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createVideo(callback?: Callback<IVideo[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_VIDEO });

		try {
			const { login, video } = getState();

			let { items } = video;

			let response = await api.sendPost("/Video", items, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_VIDEO_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_VIDEO_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_VIDEO_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getVideos(callback?: Callback<IVideo[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_VIDEO });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Video", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ALL_VIDEO_SUCCESS,
					payload: shuffle(result.model)
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_VIDEO_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALL_VIDEO_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
