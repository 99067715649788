import * as api from "services/api";
import { getErrors } from "helpers/error";
import ILead from "Types/Lead";
import { Callback } from "Types/Callback";

export const GET_CITIES = "[ADDRESS] GET_ALL";
export const GET_CITIES_SUCCESS = "[ADDRESS] GET_ALL_SUCCESS";
export const GET_CITIES_FAILED = "[ADDRESS] GET_ALL_FAILED";

export const SET_VALUE = "[ADDRESS] SET_VALUE";
export const CLEAR_VALUES = "[ADDRESS] CLEAR_VALUES";

export function getCities(callback?: Callback<ILead[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CITIES });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Address/City?neigborhood=1", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CITIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CITIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CITIES_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
