import * as actions from "store/actions/video";
import IVideo from "Types/Video";

export interface VideoState {
	loading: any;
	items: IVideo[];
	errors: any;
}

const initialState: VideoState = {
	loading: {},
	items: [],
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_VIDEO: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_VIDEO_SUCCESS: {
			return {
				...state,
				items: payload,
				loading: { create: false }
			};
		}
		case actions.CREATE_VIDEO_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.GET_ALL_VIDEO: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ALL_VIDEO_SUCCESS: {
			return {
				...initialState,
				items: payload || []
			};
		}
		case actions.GET_ALL_VIDEO_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
