import _ from "lodash";
import ls from "../Localization";

export function getErrors(errors: any) {
	const toReturn: any = {};
	for (const error of _.toPairs<any>(errors)) {
		toReturn[error[0]] = ls[error[1]] || error[1];
	}
	return toReturn;
}
