import * as actions from "../../actions/configuration";

export interface ConfigurationState {
	loading: any;
	item: any;
	errors: any;
}

const initialState: ConfigurationState = {
	loading: {},
	item: {
		chat: "",
		pixel: "",
		analytics: "",
		cub: 0,
		cubMonth: new Date(),
		cubPercentage: 0
	},
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.SAVE_CONFIGURATION: {
			return {
				...state,
				loading: { save: true },
				errors: initialState.errors
			};
		}
		case actions.SAVE_CONFIGURATION_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: { save: false }
			};
		}
		case actions.SAVE_CONFIGURATION_FAILED: {
			return {
				...state,
				loading: { save: false },
				errors: payload
			};
		}
		case actions.GET_CONFIGURATION: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONFIGURATION_SUCCESS: {
			return {
				...initialState,
				item: payload || []
			};
		}
		case actions.GET_CONFIGURATION_FAILED: {
			return {
				...state,
				loading: { etAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
