import * as api from "services/api";
import { getErrors } from "helpers/error";
import { Callback } from "Types/Callback";

export const SAVE_CONFIGURATION = "[CONFIGURATION SAVE] SAVE";
export const SAVE_CONFIGURATION_SUCCESS = "[CONFIGURATION SAVE] SAVE_SUCCESS";
export const SAVE_CONFIGURATION_FAILED = "[CONFIGURATION SAVE] SAVE_FAILED";

export const GET_CONFIGURATION = "[CONFIGURATION] GET";
export const GET_CONFIGURATION_SUCCESS = "[CONFIGURATION] GET_SUCCESS";
export const GET_CONFIGURATION_FAILED = "[CONFIGURATION] GET_FAILED";

export const SET_VALUE = "[CONFIGURATION] SET_VALUE";
export const CLEAR_VALUES = "[CONFIGURATION] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function saveConfiguration(callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: SAVE_CONFIGURATION });

		try {
			const { login, configuration } = getState();

			let { item } = configuration;

			let response = await api.sendPost("/Configuration", item, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SAVE_CONFIGURATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: SAVE_CONFIGURATION_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: SAVE_CONFIGURATION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getConfiguration(callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONFIGURATION });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Configuration", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONFIGURATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONFIGURATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			return dispatch({
				type: GET_CONFIGURATION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
