import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import ResultModel from "models/ResultModel";
import watermarkjs from "watermarkjs";

import compressorjs from "compressorjs";

import _ from "lodash";

export const EDIT_PROPERTY = "[PROPERTY EDIT] EDIT";
export const EDIT_PROPERTY_SUCCESS = "[PROPERTY EDIT] EDIT_SUCCESS";
export const EDIT_PROPERTY_FAILED = "[PROPERTY EDIT] EDIT_FAILED";

export const GET_PROPERTY = "[PROPERTY EDIT] GET";
export const GET_PROPERTY_SUCCESS = "[PROPERTY EDIT] GET_SUCCESS";
export const GET_PROPERTY_FAILED = "[PROPERTY EDIT] GET_FAILED";

export const SET_VALUE = "[PROPERTY EDIT] SET_VALUE";
export const CLEAR_VALUES = "[PROPERTY EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function editProperty(id: string, pictures: any[], callback?: Function) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_PROPERTY });

		try {
			const { login, propertyEdit } = getState();

			const {
				item,
				code,
				title,
				link,
				price,
				rooms,
				suites,
				parkingSpaces,
				privateArea,
				totalArea,
				status,
				category,
				description,
				selectedFeatures,
				address,
				incorporation,
				state,
				type,
				videos,
				furnished
			} = propertyEdit;

			const errors: any = {};

			if (!code) errors.code = ls.required;
			if (!title) errors.title = ls.required;
			if (!address.city) errors.city = ls.required;
			if (!address.state) errors.state = ls.required;
			if (!(price ?? false)) errors.price = ls.required;

			if (Object.keys(errors).length > 0)
				return dispatch({ type: EDIT_PROPERTY_FAILED, payload: errors });

			const model: any = {
				code: code.trim(),
				title: title.trim(),
				link:
					link ||
					title
						.toLowerCase()
						.trim()
						.replace(/\s+/g, "-"),
				price: Math.round(price * 100),
				rooms,
				suites,
				parkingSpaces,
				privateArea,
				totalArea,
				status,
				photos: (pictures || []).filter(c => !c.file).map(c => c.src),
				category,
				description,
				features: Object.keys(selectedFeatures).filter(
					c => selectedFeatures[c]
				),
				address: {
					...item.address,
					...address
				},
				incorporation,
				state,
				type,
				videos,
				furnished
			};

			let files = (pictures || []).filter(c => c.file);

			if (files.length > 0) {
				let blobs: Blob[] = [];
				for (const file of files) {
					blobs.push(
						await new Promise(resolve => {
							watermarkjs([file.file, "/img/watermark.png"])
								.blob(watermarkjs.image.center(0.9))
								.then(
									(blob: Blob) =>
										new compressorjs(blob, {
											quality: 0.9,
											maxHeight: 1600,
											maxWidth: 1600,
											convertSize: 800000,
											success(file) {
												resolve(file);
											}
										})
								);
						})
					);
				}

				let chunks = _.chunk(blobs, 5);

				for (const chunk of chunks) {
					let response = await api.sendPostFiles("/Property/Upload", chunk, {
						Authorization: "Bearer " + login.token
					});

					let result = (await response.json()) as ResultModel<string[]>;

					if (result.isValid) {
						model.photos = [...model.photos, ...result.model];
					} else {
						const errors = getErrors(result.errors);

						alert(
							"Ocorreu um erro ao tentar adicionar algumas imagens, verifique quais imagens não foram salvas e tente novamente. "
						);
						console.log(errors);
					}
				}
			}

			let response = await api.sendPut("/Property/" + item._id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: EDIT_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getProperty(id: string, callback?: Function) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PROPERTY });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Property/" + id, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
