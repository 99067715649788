import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import AppRouter from "./views/AppRouter";

import "./client.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import storeCreator from "./store";

const { store, persistor } = storeCreator();

const App: React.FC = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<ThemeProvider theme={theme}>
							<AppRouter />
						</ThemeProvider>
					</PersistGate>
				</Provider>
			</BrowserRouter>
		</div>
	);
};

export default App;
