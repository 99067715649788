import * as actions from "store/actions/property";
import IProperty from "Types/Property";
import IReserve from "Types/Reserve";

export interface PropertyState {
	loading: any;
	items: IProperty[];
	reserves: IReserve[];
	item: IProperty;
	errors: any;
}

const initialState: PropertyState = {
	loading: {},
	items: [],
	reserves: [],
	item: null,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_ALL_PROPERTY: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ALL_PROPERTY_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_ALL_PROPERTY_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.GET_RESERVES: {
			return {
				...state,
				loading: { getReserves: true },
				errors: initialState.errors
			};
		}
		case actions.GET_RESERVES_SUCCESS: {
			return {
				...state,
				reserves: payload || [],
				loading: { getReserves: false }
			};
		}
		case actions.GET_RESERVES_FAILED: {
			return {
				...state,
				loading: { getReserves: false },
				errors: payload
			};
		}
		case actions.GET_PROPERTY: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_PROPERTY_SUCCESS: {
			return {
				...initialState,
				item: payload,
				loading: { get: false }
			};
		}
		case actions.GET_PROPERTY_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
