import * as actions from "store/actions/address";
import ICity from "Types/City";

export interface AddressState {
	loading: any;
	cities: ICity[];
	errors: any;
}

const initialState: AddressState = {
	loading: {},
	cities: [],
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CITIES: {
			return {
				...state,
				loading: { getCities: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CITIES_SUCCESS: {
			return {
				...state,
				cities: payload || [],
				loading: { getCities: false }
			};
		}
		case actions.GET_CITIES_FAILED: {
			return {
				...state,
				loading: { getCities: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
