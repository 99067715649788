import * as actions from "store/actions/selection";
import ISelection from "Types/Selection";

export interface SelectionState {
	loading: any;
	items: ISelection[];
	errors: any;
}

const initialState: SelectionState = {
	loading: {},
	items: [],
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_ALL_SELECTION: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ALL_SELECTION_SUCCESS: {
			return {
				...initialState,
				items: payload || []
			};
		}
		case actions.GET_ALL_SELECTION_FAILED: {
			return {
				...state,
				loading: { etAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
