import LocalizedStrings from "react-localization";

import pt from "./pt";
// import en from "./en";

const ls = new LocalizedStrings({
  pt,
  // en
});

export default ls;
