import * as actions from "store/actions/banner";
import IBanner from "Types/Banner";

export interface BannerState {
	loading: any;
	items: IBanner[];
	errors: any;
}

const initialState: BannerState = {
	loading: {},
	items: [],
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_BANNER: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_BANNER_SUCCESS: {
			return {
				...state,
				items: payload,
				loading: { create: false }
			};
		}
		case actions.CREATE_BANNER_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.GET_ALL_BANNER: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ALL_BANNER_SUCCESS: {
			return {
				...initialState,
				items: payload || []
			};
		}
		case actions.GET_ALL_BANNER_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
