import { combineReducers } from "redux";

import login from "./login";
import userCreate from "./user/create";
import propertyCreate, { PropertyCreateState } from "./property/create";
import propertyEdit, { PropertyEditState } from "./property/edit";
import property, { PropertyState } from "./property";
import banner, { BannerState } from "./banner";
import video, { VideoState } from "./video";
import configuration, { ConfigurationState } from "./configuration";
import lead, { LeadState } from "./lead";
import address, { AddressState } from "./address";
import selection, { SelectionState } from "./selection";

export interface State {
	address: AddressState;
	banner: BannerState;
	configuration: ConfigurationState;
	login: any;
	useCreate: any;
	lead: LeadState;
	selection: SelectionState;
	property: PropertyState;
	propertyCreate: PropertyCreateState;
	propertyEdit: PropertyEditState;
	video: VideoState;
}

const reducers = {
	address,
	banner,
	configuration,
	login,
	userCreate,
	lead,
	selection,
	property,
	propertyCreate,
	propertyEdit,
	video
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
