import * as create from "./create";
import * as api from "services/api";
import { getErrors } from "helpers/error";
import ISelection from "Types/Selection";
import { Callback } from "Types/Callback";
export { create };

export const GET_ALL_SELECTION = "[SELECTION] GET_ALL";
export const GET_ALL_SELECTION_SUCCESS = "[SELECTION] GET_ALL_SUCCESS";
export const GET_ALL_SELECTION_FAILED = "[SELECTION] GET_ALL_FAILED";

export const SET_VALUE = "[SELECTION] SET_VALUE";
export const CLEAR_VALUES = "[SELECTION] CLEAR_VALUES";

export function getSelections(callback?: Callback<ISelection[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_SELECTION });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Selection", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ALL_SELECTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_SELECTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALL_SELECTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
