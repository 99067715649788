import { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import { useSelector } from "react-redux";
import { isLogged } from "helpers/auth";

const Dashboard = lazy(() => import("../Dashboard"));
const Login = lazy(() => import("../Login"));

function AppRouter() {
	const login = useSelector((s: any) => s.login);

	const loggedIn = isLogged(login);

	const children = (
		<Switch>
			<Route exact path="/" render={props => <Redirect to="/Dashboard" />} />
			<Route
				path="/Dashboard"
				render={props =>
					loggedIn ? <Dashboard {...props} /> : <Redirect to="/Login" />
				}
			/>
			<Route
				path="/Login"
				render={props =>
					!loggedIn ? <Login {...props} /> : <Redirect to="/Dashboard" />
				}
			/>
		</Switch>
	);

	return (
		<Suspense
			fallback={
				<div
					style={{
						height: "100vh",
						width: "100vw",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<CircularProgress size={50} />
				</div>
			}
		>
			{children}
		</Suspense>
	);
}

export default AppRouter;
