const jsonwebtoken = require("jsonwebtoken");

export function isLogged(login: any) {
	const { token, user } = login;

	if (token) {
		const decodedToken = jsonwebtoken.decode(token, { complete: true });

		if (
			decodedToken &&
			user &&
			decodedToken.payload[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
			] === user.id
		) {
			return true;
		}
	}
	return false;
}

export const isInRole = (user: any, role: any) => {
	if (!user) return false;

	if (!user.roles) return false;

	return user.roles.includes(role);
};
