const strings = {
	appName: "HomeSilk",
	about: "Sobre",
	home: "Início",
	queue: "Fila",
	table: "Mesa",
	tables: "Mesas",
	booking: "Reserva",
	reports: "Relatórios",
	queues: "Filas",
	features: "Recursos",
	download: "Baixar",
	places: "Lugares",
	contact: "Contato",
	description: "Descrição",
	new: "Novo",
	called: "Chamado",
	done: "Finalizado",
	doneBooking: "Reservas finalizadas",
	canceledOrRejectBooking: "Reservas canceladas ou rejeitadas",
	canceled: "Cancelado",
	yes: "Sim",
	no: "Não",
	name: "Nome",
	email: "E-mail",
	emailPlaceholder: "joaosilva@gmail.com",
	namePlaceholder: "João",
	lastName: "Sobrenome",
	lastNamePlaceholder: "Silva",
	pendings: "Pendentes",
	pending: "Pendente",
	confirm: "Confirmar",
	reject: "Rejeitar",
	confirmed: "Confirmado",
	rejected: "Rejeitado",
	calleds: "Chamadas",
	subject: "Assunto",
	sendMessage: "Enviar",
	sendMessageSuccess: "Sucesso! Sua mensagem foi enviada",
	sendMessageError:
		"Oops! Ocorreu um erro ao tentar enviar sua mensagem, tente novamente",
	signUp: "Cadastrar",
	signIn: "Entrar",
	loginIn: "Entrar em",
	forgotPassword: "Esqueceu sua Senha",
	dontHaveAnAccount: "Não tem uma conta",
	required: "Obrigatório",
	clear: "Limpar",
	nextPassword: "Próxima senha",
	"Invalid email.": "E-mail não cadastrado",
	"Invalid password.": "Senha incorreta",
	number: "Número",
	invalid: "Inválido",
	qrCode: "QR Code",
	connect: "Conectar",
	readTheQrCodeInTheApp: "Leia o QRCode no Aplicativo",
	waitingTime: "Tempo de espera",
	minutes: "Minutos",
	people: "Pessoas",
	minPeople: "Mínimo de Pessoas",
	numberOfPeople: "Nº Pessoas",
	resetPassword: "Zerar senha",
	phoneNumber: "Telefone",
	phoneNumberPlaceholder: "+55 12 93456 7890",
	status: "Status",
	client: "Cliente",
	noTable: "Nenhuma mesa",
	bookingNotConfirmed: "Reserva não confirmada",
	areYouSureYouWantToCallThisCustomer:
		"Tem certeza que deseja chamar este cliente",
	areYouSureYouWantToCancelThisQueue:
		"Tem certeza que deseja cancelar esta senha",
	areYouSureYouWantToCancelThisBooking:
		"Tem certeza que deseja cancelar esta reserva",
	areYouSureYouWantToFinishThisQueue:
		"Tem certeza que deseja finalizar esta senha",
	areYouSureYouWantToConfirmThisBooking:
		"Tem certeza que deseja confirmar esta reserva",
	areYouSureYouWantToFinishThisBooking:
		"Tem certeza que deseja finalizar esta reserva",
	areYouSureYouWantToRejectThisBooking:
		"Tem certeza que deseja rejeitar esta reserva",
	areYouSureYouWantToDeleteThisTable:
		"Tem certeza que deseja deletar esta mesa",
	ifYouContinueDeleteTableMessage:
		"Se você continuar, não será possível desfazer",
	ifYouContinueFinishQueueMessage:
		"Se você continuar, estará informando que o cliente já foi atendido",
	ifYouContinueFinishBookingMessage:
		"Se você continuar, estará informando que o cliente já foi atendido",
	areYouSure: "Você tem certeza",
	dateFormat: "DD/MM/YYYY - HH:mm:ss",
	dateFormatWithoutSeconds: "DD/MM/YYYY - HH:mm",
	dateFormatWithoutYearAndSeconds: "DD MMM - HH:mm",
	dateFormatShort: "DD/MM/YYYY",
	dateFormatMonth: "MM/YYYY",
	timeFormat: "HH:mm",
	yesCancel: "Sim, cancelar!",
	cancel: "Cancelar",
	conclude: "Concluir",
	finish: "Finalizar",
	list: "Lista",
	backToLogin: "Voltar para login",
	register: "Cadastrar",
	needCreateBusinessMessage:
		"Você precisa cadastrar uma nova empresa para continuar",
	recoverPassword: "Recuperar a senha",
	newPasswordSentForEmail: "Uma nova senha foi enviada para o e-mail informado",
	businessData: "Dados do estabelecimento",
	businessName: "Razão Social",
	socialName: "Nome fantasia",
	search: "Pesquisar",
	cnpj: "CNPJ",
	zipCode: "CEP",
	street: "Endereço",
	complement: "Complemento",
	neighborhood: "Bairro",
	city: "Cidade",
	state: "Estado",
	country: "País",
	save: "Salvar",
	invalidPhoneNumber: "Telefone inválido",
	invalidCnpj: "CNPJ inválido",
	createNewTable: "Criar nova mesa",
	numberOrName: "Número ou nome",
	schedules: "Horários",
	open: "Aberto",
	closed: "Fechado",
	actions: "Ações",
	close: "Fechar",
	dateTime: "Data e hora",
	info: "Informações",
	privateInfo: "Informações privadas",
	person: "Pessoa",
	requiredIfEmailNotInformed: "Obrigatório se o e-mail não for informado",
	requiredIfPhoneNotInformed: "Obrigatório se o telefone não for informado",
	dateNeedToBeAfterNow: "Data precisa ser depois de agora",
	today: "Hoje",
	tomorrow: "Amanhã",
	otherDays: "Outros dias",
	time: "Horário",
	reserved: "Reservada",
	reserves: "Reservas",
	edit: "Editar",
	oops: "Oops",
	address: "Endereço",
	visible: "Visível",
	backgroundColor: "Cor de fundo",
	creationDate: "Data criação",
	notDefined: "Não definido",
	categories: "Categorias",
	category: "Categoria",
	loading: "Carregando",
	users: "Usuários",
	role: "Função/Cargo",
	addUser: "Adicionar usuário",
	rolePlaceholder: "Selecione uma função/cargo",
	"Business Admin": "Administrador da Empresa",
	Manager: "Gerente",
	User: "Usuário",
	property: "Imóveis",
	title: "Título",
	linkText: "Link permanente",
	code: "Código",
	noneSelectedText: "Selecione uma opção",
	type: "Tipo",
	price: "Preço",
	rooms: "Quartos",
	suites: "Suites",
	vacancies: "Vagas",
	privateArea: "Área privada(m²)",
	totalArea: "Área total(m²)",
	incorporation: "Incorporação",
	leads: "Leads",
	source: "Fonte",
	requests: "Solicitações",
	range: "Faixa",
	Launch: "Lançamento",
	Construction: "Construção",
	New: "Novo",
	Used: "Usado",
	furnished: "Mobiliado",
	starDate: "Inicio",
	endDate: "Fim",
	dashboard: "Dashboard",
	properties: "Imóveis",
	propertyCreate: "Criar novo imóvel",
	propertyEdit: "Editar imóvel",
	releases: "Lançamentos",
	searchProperties: "Procurar imóveis",
	saleProperties: "Imóveis à venda",
	rentalProperties: "Imóveis para locação anual",
	seasonProperties: "Imóveis para locação de temporada",
	homeTitle: "Imóveis para alugar e comprar em Bombinhas, Porto Belo e Itapema",
	"Duplicated code": "Código duplicado",
	"Duplicated title": "Título duplicado",
	banners: "Banners",
	configurations: "Configurações",
	chatCode: "Código do chat",
	tawkCode: "Código do Tawk.to",
	facePixel: "Pixel do Facebook",
	facePixelCode: "Código do Pixel do Facebook",
	googleAnalytics: "Google Analytics",
	googleAnalyticsCode: "Código do Google Analytics",
	cubPrice: "Preço do CUB",
	cub: "CUB",
	cubPercentage: "Variação",
	cubMonth: "Mês",
	videos: "Vídeos",
	url: "Url",
	AprovePending: "Aprovação Pendente"
};

export default strings;
