import * as actions from "store/actions/property/edit";
import IPropertyFeature from "Types/PropertyFeature";
import IProperty, {
	PropertyCategory,
	PropertyStatus,
	PropertyState,
	PropertyType
} from "Types/Property";
import IAddress from "Types/Address";

export interface PropertyEditState {
	item?: IProperty;
	loading?: any;
	errors?: any;
	code?: string;
	title?: string;
	link?: string;
	price?: number;
	rooms?: number;
	suites?: number;
	parkingSpaces?: number;
	privateArea?: number;
	totalArea?: number;
	selectedFeatures?: any;
	category?: PropertyCategory;
	status?: PropertyStatus;
	state?: PropertyState;
	type?: PropertyType;
	description?: string;
	address?: IAddress;
	incorporation?: string;
	furnished?: boolean;
	photos?: string[];
	videos?: string[];
}

const initialState: PropertyEditState = {
	item: null,
	loading: {},
	errors: {},
	code: "",
	title: "",
	link: "",
	price: 0,
	rooms: 1,
	suites: 0,
	parkingSpaces: 0,
	privateArea: 0,
	totalArea: 0,
	selectedFeatures: {},
	category: 0,
	status: 0,
	state: 0,
	type: 0,
	furnished: false,
	description: "",
	address: {
		street: "",
		number: "",
		complement: "",
		neighborhood: "",
		city: "Bombinhas",
		state: "SC",
		country: "BR",
		zipCode: ""
	},
	incorporation: "",
	photos: [],
	videos: []
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.EDIT_PROPERTY: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_PROPERTY_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_PROPERTY_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.GET_PROPERTY: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_PROPERTY_SUCCESS: {
			return {
				...state,
				item: payload,
				code: payload?.code || initialState.code,
				title: payload?.title || initialState.title,
				link: payload?.link || initialState.link,
				price: payload?.price / 100 || initialState.price,
				rooms: payload?.rooms || initialState.rooms,
				suites: payload?.suites || initialState.suites,
				parkingSpaces: payload?.parkingSpaces || initialState.parkingSpaces,
				privateArea: payload?.privateArea || initialState.privateArea,
				totalArea: payload?.totalArea || initialState.totalArea,
				selectedFeatures:
					payload?.features?.reduce(
						(a: any, b: IPropertyFeature) => ({ ...a, [b._id]: true }),
						{}
					) || initialState.selectedFeatures,
				category: payload?.category || initialState.category,
				status: payload ? payload.status : initialState.status,
				state: payload?.state || initialState.state,
				type: payload?.type || initialState.type,
				furnished: payload?.furnished || initialState.furnished,
				description: payload?.description || initialState.description,
				address: {
					street: payload?.address?.street || initialState.address.street,
					number: payload?.address?.number || initialState.address.number,
					complement:
						payload?.address?.complement || initialState.address.complement,
					neighborhood:
						payload?.address?.neighborhood || initialState.address.neighborhood,
					city: payload?.address?.city || initialState.address.city,
					state: payload?.address?.state || initialState.address.state,
					country: payload?.address?.country || initialState.address.country,
					zipCode: payload?.address?.zipCode || initialState.address.zipCode
				},
				incorporation: payload?.incorporation || initialState.incorporation,
				photos: payload?.photos || initialState.photos,
				videos: payload?.videos || initialState.videos
			};
		}
		case actions.GET_PROPERTY_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload)
				return {
					...initialState
				};

			return {
				...initialState,
				item: state.item,
				code: state.item?.code || initialState.code,
				title: state.item?.title || initialState.title,
				link: state.item?.link || initialState.link,
				price: state.item?.price / 100 || initialState.price,
				rooms: state.item?.rooms || initialState.rooms,
				suites: state.item?.suites || initialState.suites,
				parkingSpaces: state.item?.parkingSpaces || initialState.parkingSpaces,
				privateArea: state.item?.privateArea || initialState.privateArea,
				totalArea: state.item?.totalArea || initialState.totalArea,
				selectedFeatures:
					state.item?.features?.reduce(
						(a: any, b: IPropertyFeature) => ({ ...a, [b._id]: true }),
						{}
					) || initialState.selectedFeatures,
				category: state.item?.category || initialState.category,
				status: state.item ? state.item.status : initialState.status,
				state: state.item?.state || initialState.state,
				type: state.item?.type || initialState.type,
				furnished: state.item?.furnished || initialState.furnished,
				description: state.item?.description || initialState.description,
				address: {
					street: state.item?.address?.street || initialState.address.street,
					number: state.item?.address?.number || initialState.address.number,
					complement:
						state.item?.address?.complement || initialState.address.complement,
					neighborhood:
						state.item?.address?.neighborhood ||
						initialState.address.neighborhood,
					city: state.item?.address?.city || initialState.address.city,
					state: state.item?.address?.state || initialState.address.state,
					country: state.item?.address?.country || initialState.address.country,
					zipCode: state.item?.address?.zipCode || initialState.address.zipCode
				},
				incorporation: state.item?.incorporation || initialState.incorporation,
				photos: state.item?.photos || initialState.photos,
				videos: state.item?.videos || initialState.videos
			};
		default: {
			return state;
		}
	}
};

export default reducer;
