import * as create from "./create";
import * as edit from "./edit";
import * as api from "services/api";
import { getErrors } from "helpers/error";
import IProperty from "Types/Property";
import { Callback } from "Types/Callback";
import LogRocket from "logrocket";

export { create, edit };

export const GET_ALL_PROPERTY = "[PROPERTY] GET_ALL";
export const GET_ALL_PROPERTY_SUCCESS = "[PROPERTY] GET_ALL_SUCCESS";
export const GET_ALL_PROPERTY_FAILED = "[PROPERTY] GET_ALL_FAILED";

export const GET_RESERVES = "[PROPERTY] GET_RESERVES";
export const GET_RESERVES_SUCCESS = "[PROPERTY] GET_RESERVES_SUCCESS";
export const GET_RESERVES_FAILED = "[PROPERTY] GET_RESERVES_FAILED";

export const GET_PROPERTY = "[PROPERTY] GET";
export const GET_PROPERTY_SUCCESS = "[PROPERTY] GET_SUCCESS";
export const GET_PROPERTY_FAILED = "[PROPERTY] GET_FAILED";

export const RESERVE_PROPERTY = "[PROPERTY] RESERVE";
export const RESERVE_PROPERTY_SUCCESS = "[PROPERTY] RESERVE_SUCCESS";
export const RESERVE_PROPERTY_FAILED = "[PROPERTY] RESERVE_FAILED";

export const SET_VALUE = "[PROPERTY] SET_VALUE";
export const CLEAR_VALUES = "[PROPERTY] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getProperties(callback?: Callback<IProperty[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_PROPERTY });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Property", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			
			if (result.isValid) {
				dispatch({
					type: GET_ALL_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: GET_ALL_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getReserves(callback?: Callback<IProperty[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_RESERVES });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Property/Reserve", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_RESERVES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_RESERVES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: GET_RESERVES_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getPropertyByCode(
	code: string,
	callback?: Callback<IProperty>,
	header?: boolean
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PROPERTY });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Property/Code/" + code, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid && result.model) {
				dispatch({
					type: GET_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else if (header && result.isValid && !result.model) {
				dispatch({
					type: GET_PROPERTY_FAILED,
					payload: {}
				});

				callback && callback();
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: GET_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function search(options: any = {}, callback?: Callback<IProperty[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_PROPERTY });

		try {
			const { login } = getState();

			var response = await api.sendPost("/Property/Search", options, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();
			
			if (result.isValid) {
				dispatch({
					type: GET_ALL_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: GET_ALL_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function reserve(id: string, model: any, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: RESERVE_PROPERTY });

		try {
			const { login } = getState();

			var response = await api.sendPost("/Property/Reserve/" + id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: RESERVE_PROPERTY_SUCCESS,
					payload: result.model
				});

				if (process.env.RAZZLE_LOG_ROCKET) {
					LogRocket.identify(result.model._id, {
						name: result.model.name,
						email: result.model.email,
						phone: result.model.phone
					});
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: RESERVE_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: RESERVE_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
