import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_USER = "[USER CREATE] CREATE_USER";
export const CREATE_USER_SUCCESS = "[USER CREATE] CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "[USER CREATE] CREATE_USER_FAILED";

export const SET_VALUE = "[USER CREATE] SET_VALUE";
export const CLEAR_VALUES = "[USER CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createUser(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_USER });

		try {
			const { login } = getState();

			var response = await api.sendPost("/User", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_USER_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_USER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_USER_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
