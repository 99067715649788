import * as api from "services/api";
import { getErrors } from "helpers/error";
import { Callback } from "Types/Callback";
import ISelection from "Types/Selection";
import LogRocket from "logrocket";

export const CREATE_SELECTION = "[SELECTION CREATE] CREATE";
export const CREATE_SELECTION_SUCCESS = "[SELECTION CREATE] CREATE_SUCCESS";
export const CREATE_SELECTION_FAILED = "[SELECTION CREATE] CREATE_FAILED";

export const SET_VALUE = "[SELECTION CREATE] SET_VALUE";
export const CLEAR_VALUES = "[SELECTION CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createSelection(model, callback?: Callback<ISelection>) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_SELECTION });

		try {
			let response = await api.sendPost("/Selection", model);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_SELECTION_SUCCESS,
					payload: result.model
				});

				if (process.env.RAZZLE_LOG_ROCKET) {
					LogRocket.identify(result.model._id, {
						name: result.model.name,
						email: result.model.email,
						phone: result.model.phone
					});
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_SELECTION_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_SELECTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
