import * as actions from "store/actions/property/create";
import IPropertyFeature from "Types/PropertyFeature";
import {
	PropertyCategory,
	PropertyStatus,
	PropertyState,
	PropertyType
} from "Types/Property";
import IAddress from "Types/Address";

export interface PropertyCreateState {
	loading?: any;
	errors?: any;
	code?: string;
	title?: string;
	link?: string;
	price?: number;
	rooms?: number;
	suites?: number;
	parkingSpaces?: number;
	privateArea?: number;
	totalArea?: number;
	selectedFeatures?: any[];
	features?: IPropertyFeature[];
	category?: PropertyCategory;
	status?: PropertyStatus;
	state?: PropertyState;
	type?: PropertyType;
	description?: string;
	address?: IAddress;
	incorporation?: string;
	furnished?: boolean;
	photos?: string[];
	videos?: string[];
}

const initialState: PropertyCreateState = {
	loading: {},
	errors: {},
	code: "",
	title: "",
	link: "",
	price: 0,
	rooms: 1,
	suites: 0,
	parkingSpaces: 0,
	privateArea: 0,
	totalArea: 0,
	features: [],
	selectedFeatures: [],
	category: 0,
	status: 0,
	state: 0,
	type: 0,
	furnished: false,
	description: "",
	address: {
		street: "",
		number: "",
		complement: "",
		neighborhood: "",
		city: "Bombinhas",
		state: "SC",
		country: "BR",
		zipCode: ""
	},
	incorporation: "",
	photos: []
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_PROPERTY: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_PROPERTY_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_PROPERTY_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.GET_PROPERTY_FEATURES: {
			return {
				...state,
				loading: { getFeatures: true },
				errors: initialState.errors
			};
		}
		case actions.GET_PROPERTY_FEATURES_SUCCESS: {
			return {
				...state,
				features: payload || [],
				loading: { getFeatures: false }
			};
		}
		case actions.GET_PROPERTY_FEATURES_FAILED: {
			return {
				...state,
				loading: { getFeatures: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
