import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import ResultModel from "models/ResultModel";
import watermarkjs from "watermarkjs";

import _ from "lodash";

import compressorjs from "compressorjs";

export const CREATE_PROPERTY = "[PROPERTY CREATE] CREATE";
export const CREATE_PROPERTY_SUCCESS = "[PROPERTY CREATE] CREATE_SUCCESS";
export const CREATE_PROPERTY_FAILED = "[PROPERTY CREATE] CREATE_FAILED";

export const GET_PROPERTY_FEATURES = "[PROPERTY CREATE] GET_PROPERTY_FEATURES";
export const GET_PROPERTY_FEATURES_SUCCESS =
	"[PROPERTY CREATE] GET_PROPERTY_FEATURES_SUCCESS";
export const GET_PROPERTY_FEATURES_FAILED =
	"[PROPERTY CREATE] GET_PROPERTY_FEATURES_FAILED";

export const SET_VALUE = "[PROPERTY CREATE] SET_VALUE";
export const CLEAR_VALUES = "[PROPERTY CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createProperty(files: any[], callback?: Function) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_PROPERTY });

		try {
			const { login, propertyCreate } = getState();

			const {
				code,
				title,
				link,
				price,
				rooms,
				suites,
				parkingSpaces,
				privateArea,
				totalArea,
				status,
				category,
				description,
				selectedFeatures,
				address,
				incorporation,
				state,
				type,
				furnished
			} = propertyCreate;

			const errors: any = {};

			if (!code) errors.code = ls.required;
			if (!title) errors.title = ls.required;
			if (!address.city) errors.city = ls.required;
			if (!address.state) errors.state = ls.required;
			if (!(price ?? false) && price !== 0) errors.price = ls.required;

			if (Object.keys(errors).length > 0)
				return dispatch({ type: CREATE_PROPERTY_FAILED, payload: errors });

			const model: any = {
				code: code.trim(),
				title: title.trim(),
				link:
					link ||
					title
						.toLowerCase()
						.trim()
						.replace(/\s+/g, "-"),
				price: Math.round(price * 100),
				rooms,
				suites,
				parkingSpaces,
				privateArea,
				totalArea,
				status,
				category,
				description,
				features: Object.keys(selectedFeatures).filter(
					c => selectedFeatures[c]
				),
				address,
				photos: [],
				incorporation,
				state,
				type,
				furnished
			};

			if (files && files.length > 0) {
				let blobs: Blob[] = [];
				for (const file of files) {
					blobs.push(
						await new Promise(resolve => {
							watermarkjs([file.file, "/img/watermark.png"])
								.blob(watermarkjs.image.center(0.9))
								.then(
									(blob: Blob) =>
										new compressorjs(blob, {
											quality: 0.9,
											maxHeight: 1600,
											maxWidth: 1600,
											convertSize: 800000,
											success(file) {
												resolve(file);
											}
										})
								);
						})
					);
				}

				let chunks = _.chunk(blobs, 5);

				for (const chunk of chunks) {
					let response = await api.sendPostFiles("/Property/Upload", chunk, {
						Authorization: "Bearer " + login.token
					});

					let result = (await response.json()) as ResultModel<string[]>;

					if (result.isValid) {
						model.photos = [...model.photos, ...result.model];
					} else {
						const errors = getErrors(result.errors);

						alert(
							"Ocorreu um erro ao tentar adicionar algumas imagens, verifique quais imagens não foram salvas e tente novamente. "
						);
						console.log(errors);
					}
				}
			}

			let response = await api.sendPost("/Property", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_PROPERTY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_PROPERTY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getFeatures(callback?: Function) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PROPERTY_FEATURES });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Property/Feature", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PROPERTY_FEATURES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PROPERTY_FEATURES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PROPERTY_FEATURES_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
