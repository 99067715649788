import * as api from "services/api";
import { getErrors } from "helpers/error";
import { Callback } from "Types/Callback";
import ILead from "Types/Lead";
import LogRocket from "logrocket";

export const CREATE_LEAD = "[LEAD CREATE] CREATE";
export const CREATE_LEAD_SUCCESS = "[LEAD CREATE] CREATE_SUCCESS";
export const CREATE_LEAD_FAILED = "[LEAD CREATE] CREATE_FAILED";

export const SET_VALUE = "[LEAD CREATE] SET_VALUE";
export const CLEAR_VALUES = "[LEAD CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createLead(model, callback?: Callback<ILead>) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_LEAD });

		try {
			let response = await api.sendPost("/Lead", model);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_LEAD_SUCCESS,
					payload: result.model
				});

				if (process.env.RAZZLE_LOG_ROCKET) {
					LogRocket.identify(result.model._id, {
						name: result.model.name,
						email: result.model.email
					});
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_LEAD_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_LEAD_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
