import * as api from "services/api";
import { getErrors } from "helpers/error";
import IBanner from "Types/Banner";
import { Callback } from "Types/Callback";
import ResultModel from "models/ResultModel";
import { shuffle } from "helpers/array";
import compressorjs from "compressorjs";

export const CREATE_BANNER = "[BANNER CREATE] CREATE";
export const CREATE_BANNER_SUCCESS = "[BANNER CREATE] CREATE_SUCCESS";
export const CREATE_BANNER_FAILED = "[BANNER CREATE] CREATE_FAILED";

export const GET_ALL_BANNER = "[BANNER] GET_ALL";
export const GET_ALL_BANNER_SUCCESS = "[BANNER] GET_ALL_SUCCESS";
export const GET_ALL_BANNER_FAILED = "[BANNER] GET_ALL_FAILED";

export const SET_VALUE = "[BANNER] SET_VALUE";
export const CLEAR_VALUES = "[BANNER] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createBanner(files: File[], callback?: Callback<IBanner[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_BANNER });

		try {
			const { login, banner } = getState();

			let { items } = banner;

			if (files && files.length > 0) {
				let blobs: Blob[] = [];
				for (const file of files) {
					blobs.push(
						await new Promise(resolve => {
							new compressorjs(file, {
								quality: 0.9,
								maxHeight: 1600,
								maxWidth: 1600,
								convertSize: 800000,
								success(blob) {
									resolve(blob);
								}
							});
						})
					);
				}

				let response = await api.sendPostFiles("/Banner/Upload", blobs, {
					Authorization: "Bearer " + login.token
				});

				let result = (await response.json()) as ResultModel<string[]>;

				if (result.isValid)
					items = [...items, ...result.model.map(link => ({ link }))];
				else {
					const errors = getErrors(result.errors);

					dispatch({
						type: CREATE_BANNER_FAILED,
						payload: errors
					});

					return callback && callback(errors);
				}
			}

			let response = await api.sendPost("/Banner", items, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_BANNER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_BANNER_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_BANNER_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getBanners(callback?: Callback<IBanner[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_BANNER });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Banner", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ALL_BANNER_SUCCESS,
					payload: shuffle(result.model)
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_BANNER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALL_BANNER_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
