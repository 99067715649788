import * as actions from "../../actions/login";
import dayjs from "dayjs";

const initialState = {
	recoverEmail: "",
	username: "",
	password: "",
	message: "",
	loading: false,
	user: null,
	token: null,
	expiration: null,
	freshTokenPromise: null,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.PASSWORD_RECOVERY: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.PASSWORD_RECOVERY_SUCCESS: {
			return {
				...state,
				loading: false,
				errors: initialState.errors
			};
		}
		case actions.LOGIN: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.LOGIN_SUCCESS: {
			return {
				...state,
				message: "",
				username: "",
				password: "",
				loading: false,
				token: payload.token,
				expiration: dayjs(payload.expiration),
				errors: initialState.errors
			};
		}
		case actions.GET_USER_DATA: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.SET_USER_DATA: {
			return {
				...state,
				loading: false,
				user: payload,
				errors: initialState.errors
			};
		}
		case actions.LOGIN_ERROR: {
			return {
				...state,
				loading: false,
				user: initialState.user,
				token: initialState.token,
				errors: payload
			};
		}
		case actions.REFRESH_TOKEN: {
			return {
				...state,
				loading: true,
				refreshTokenPromise: payload
			};
		}
		case actions.REFRESH_TOKEN_ERROR: {
			return {
				...state,
				loading: false,
				user: initialState.user,
				token: initialState.token,
				refreshTokenPromise: null,
				error: payload
			};
		}
		case actions.REFRESH_TOKEN_SUCCESS: {
			return {
				...state,
				refreshTokenPromise: null
			};
		}
		case actions.PASSWORD_RECOVERY_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.USER_LOGGED_OUT: {
			return {
				...state,
				...initialState
			};
		}
		case actions.SET_ERROR: {
			return {
				...state,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
				message: state.message,
				username: state.username
			};
		default: {
			return state;
		}
	}
};

export default reducer;
