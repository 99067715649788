import history from "../../../AppHistory";
import _ from "lodash";
import * as api from "../../../services/api";
import ls from "../../../Localization";
import { getErrors } from "../../../helpers/error";
import dayjs from "dayjs";

import LogRocket from "logrocket";

import ResultModel from "../../../models/ResultModel";
import TokenModel from "../../../models/TokenModel";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN = "LOGIN";

export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILED = "PASSWORD_RECOVERY_FAILED";

export const SET_USER_DATA = "[USER] SET DATA";
export const GET_USER_DATA = "[USER] GET DATA";
export const GET_USER_DATA_ERROR = "[USER] GET DATA ERROR";

export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const SET_ERROR = "[USER] SER_ERROR";

export const SET_VALUE = "[LOGIN] SET_VALUE";
export const CLEAR_VALUES = "[LOGIN] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitRecoverPassword() {
	return async (dispatch, getState) => {
		dispatch({ type: PASSWORD_RECOVERY });

		const { recoverEmail } = getState().login;

		const response = await api.sendPost(
			"/Account/RecoverPassword?email=" + recoverEmail
		);
		const json = await response.json();
		if (response.ok && json.isValid) {
			dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

			dispatch(
				setValue({
					username: recoverEmail,
					message: ls.newPasswordSentForEmail
				})
			);
			history.push({
				pathname: "/login"
			});
		} else {
			if (json.errors.default.includes("User is null")) {
				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: "E-mail inválido" }
				});
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { recoverEmail: error }
				});
			}
		}
	};
}

export function handleHttpResponse(response) {
	if (response.ok) return response.json();

	response.json().then(res => console.log(res));

	if (response.status === 401) {
		throw new Error("Http Response is Unauthorized");
	}

	throw new Error("Http Response is not ok");
}

export function handleHttpError(error) {
	return dispatch => {
		console.log(error);

		if (error.message === "Http Response is Unauthorized") {
			dispatch(logoutUser());
		}

		dispatch({
			type: REFRESH_TOKEN_ERROR
		});
	};
}

export function logoutUser() {
	return {
		type: USER_LOGGED_OUT
	};
}

export function refreshToken(dispatch, getState) {
	let {
		login: { token, user }
	} = getState();

	try {
		let refreshTokenPromise = api
			.sendPost("/account/refreshToken?token=" + token + "&userId=" + user._id)
			.then(handleHttpResponse)
			.then(json => {
				if (json.isValid && json.model) {
					let token = json.model;

					dispatch({
						type: LOGIN_SUCCESS,
						payload: token
					});

					dispatch({
						type: REFRESH_TOKEN_SUCCESS
					});
					Promise.resolve(token);
				} else {
					const error = {
						username: json.errors.includes("Invalid email.")
							? "Invalid email."
							: json.errors.includes("Account disabled.")
							? "Account disabled."
							: null,
						password: json.errors.includes("Invalid password.")
							? "Invalid password."
							: null
					};

					dispatch({
						type: REFRESH_TOKEN_ERROR,
						payload: error
					});

					Promise.reject(error);
				}
			})
			.catch(err => {
				dispatch(handleHttpError(err));
			});

		dispatch({
			type: REFRESH_TOKEN,
			payload: refreshTokenPromise
		});

		return refreshTokenPromise;
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_ERROR,
			payload: {
				error: "Connection error"
			}
		});
	}
}

export function getUserData(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USER_DATA
		});

		try {
			const { login } = getState();

			let response = await api.sendGet("/user/me", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SET_USER_DATA,
					payload: result.model
				});

				if (process.env.RAZZLE_LOG_ROCKET) {
					LogRocket.identify(result.model._id, {
						name: result.model.name + result.model.lastName,
						email: result.model.email
					});
				}

				callback && callback();
			} else {
				dispatch({
					type: GET_USER_DATA_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_DATA_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function submitLogin(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: LOGIN
		});

		const { username, password } = getState().login;

		const errors: any = {};

		if (!username) errors.username = ls.required;
		if (!password) errors.password = ls.required;

		dispatch({
			type: SET_ERROR,
			payload: errors
		});

		if (_.keys(errors).length) return;

		try {
			let response = await api.sendPost("/account/token", {
				username,
				password
			});

			let result = (await response.json()) as ResultModel<TokenModel>;

			if (result.isValid) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: result.model
				});

				dispatch(getUserData(callback));
			} else {
				dispatch({
					type: LOGIN_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function validatedAuthentication() {
	return async (dispatch, getState) => {
		// return dispatch({
		//   type: LOGIN_ERROR,
		//   payload: error
		// });
	};
}

export function jwt({ dispatch, getState }) {
	return next => action => {
		// only worry about expiring token for async actions
		if (typeof action === "function") {
			try {
				const { login } = getState();

				const { expiration, token } = login;

				if (token && expiration && dayjs(expiration).diff(dayjs()) < 5000) {
					// make sure we are not already refreshing the token
					if (getState().login.freshTokenPromise) {
						return getState().login.freshTokenPromise.then(() => next(action));
					} else {
						let refresh = refreshToken(dispatch, getState);

						if (refresh) return refresh.then(() => next(action));
					}
				}
			} catch (error) {
				console.log(error);
			}
		}
		return next(action);
	};
}
